import { useLangUrlDefault } from "../../../../hooks";

import { NavItem, NavList, StyledLink, StyledNav } from "./Navigation.styled";

export const Navigation = ({ data }) => {
  const [queryLang, hrefLang] = useLangUrlDefault();

  return (
    <StyledNav>
      <NavList>
        {data?.map(({ name, url, searchParam, localization }) => (
          <NavItem key={name}>
            <StyledLink
              to={searchParam ? `${hrefLang}/${url}/?${searchParam}` : `${hrefLang}/${url}/`}
              title={localization?.[queryLang]}
            >
              {localization?.[queryLang]}
            </StyledLink>
          </NavItem>
        ))}
      </NavList>
    </StyledNav>
  );
};
