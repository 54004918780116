import styled from "@emotion/styled";
import { Link } from "react-router-dom";

import { ACTIVE_STATUS } from "../../../../utils/constants/activeStatus.constant";
import { Icon } from "../../../ui/Icon/Icon";

export const StyledNavigation = styled.nav`
  display: flex;
  flex-direction: column;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    display: grid;
    grid-template-columns: 2fr 1fr;
    padding: 32px 0;
  }
`;

export const StyledNavigationItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  margin-bottom: ${(p) => (p.isOpened ? "24px" : "0")};

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    padding: 0 32px;
  }

  &:not(:last-child) {
    @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
      margin-bottom: 0;
      border-right: 1px solid rgba(255, 255, 255, 0.09);
    }
  }
`;

export const StyledTitle = styled.div`
  color: rgba(255, 255, 255, 0.5);
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42;
  text-transform: uppercase;

  @media (max-width: ${(p) => p.theme.screenSizes.mdMax}) {
    color: ${(p) =>
      p.isOpened ? p.theme.colors["main-500"] : "rgba(255, 255, 255, 0.5)"};
  }
`;

export const StyledTitleWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 0 12px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: ${(p) => (p.isOpened ? "0" : "24px")};
  padding-bottom: ${(p) => (p.isOpened ? "8px" : "0")};

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    margin-bottom: 16px;
    padding-bottom: 0;
  }
`;

export const StyledIcon = styled(Icon)`
  margin-left: 12px;
  color: ${(p) => (p.isOpened ? "#ffffff" : "#ffffffcc")};
  width: 24px;
  height: 24px;

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    display: none !important;
  }
`;

export const StyledList = styled.ul`
  width: 100%;
  // transition: max-height 0.5s ease, opacity 0.5s ease, visibitity 0.5s ease;

  @media (max-width: ${(p) => p.theme.screenSizes.mdMax}) {
    opacity: ${(p) => (p.isOpened ? "1" : "0")};
    max-height: ${(p) => (p.isOpened ? "100%" : "0")};

    & a {
      visibility: ${(p) => (p.isOpened ? "visible" : "hidden")};
      // transition: visibility 0.5s ease;
    }
  }

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    &[id="tools"] {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 24px;
    }
  }
`;

export const StyledListLink = styled(Link)`
  display: flex;
  width: 100%;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  color: white;
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 16px;
  font-weight: 400;
  transition: all 0.3s ease;
  background: ${(p) =>
    p.active === ACTIVE_STATUS.ACTIVE
      ? "rgba(255, 255, 255, 0.08)"
      : "transparent"};
  &:hover {
    background: rgba(255, 255, 255, 0.08);
    transition: all 0.3s ease;
  }

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    visibility: visible;
    height: auto;
    transform: translateY(0);
  }
`;

export const StyledListItem = styled.li`
  width: 100%;
  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    display: flex;
  }

  @media (max-width: ${(p) => p.theme.screenSizes.mdMax}) {
    visibility: ${(p) => (p.isOpened ? "visible" : "hidden")};
    height: ${(p) => (p.isOpened ? "auto" : "0")};
    transition: visibility 0.5s ease;
  }
`;

export const StyledListTitle = styled.span`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  @media (min-width: 1300px) {
    flex-wrap: nowrap;
  }
`;

export const StyledListDescription = styled.span`
  color: rgba(255, 255, 255, 0.5);
  font-family: ${(p) => p.theme.fontFamily.lato};
  font-size: 12px;
  font-weight: 400;
  line-height: 1.33;
  margin-top: 8px;
`;

export const StyledChildrenWrapper = styled.div`
  width: 100%;
  height: 100%;
  // transition: max-height 0.5s ease, opacity 0.5s ease;

  @media (max-width: ${(p) => p.theme.screenSizes.mdMax}) {
    opacity: ${(p) => (p.isOpened ? "1" : "0")};
    max-height: ${(p) => (p.isOpened ? "100%" : "0")};
    margin-top: ${(p) => (p.isOpened ? "8px" : "0")};
  }

  @media (min-width: ${(p) => p.theme.screenSizes.lgMin}) {
    position: relative;
  }
`;
